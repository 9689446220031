@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: tgt;
  src: url(fonts/tex-gyre-termes/texgyretermes-regular.otf);
}
@font-face {
  font-family: tgt-bold;
  src: url(fonts/tex-gyre-termes/texgyretermes-bold.otf);
}
@font-face {
  font-family: ITC;
  src: url(fonts/ITCAvantGardeStd-Bk.ttf);
}
@font-face {
  font-family: ITCBOLD;
  src: url('./fonts/ITCAvantGardeStd-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
* {
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  font-family: tgt;
  scroll-behavior: smooth;
  color: #333;
  -webkit-overflow-scrolling: touch;
  z-index: 1px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
h1 {
  overflow: hidden
}
body {
  -webkit-overflow-scrolling: touch;
}
.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}
@media only screen and (max-width:767px){
  html, body {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    scroll-behavior: smooth;
  }
}
